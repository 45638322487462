<template>
  <div class="pageMain">
    <search-head>
      <el-form :inline="true" :model="searchForm" @reset="headReset" @search="headSearch">
        <el-form-item label="发送日期">
          <el-date-picker
              v-model="searchForm.date"
              size="small"
              clearable
              type="datetimerange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="接收人">
          <el-input size="small" clearable v-model="searchForm.person" placeholder="请输入接收人"></el-input>
        </el-form-item>
        <el-form-item label="手机号码">
          <el-input size="small" clearable v-model="searchForm.compCode" placeholder="请输入手机号码"></el-input>
        </el-form-item>
      </el-form>
    </search-head>
    <custom-tables :data="tableData" :total="total"
                   noall="true"
                   title="短信记录"
                   @selectionChange="selectionChange"
                   @handleCurrentChange="handleCurrentChange"
                   @handleSizeChange="handleSizeChange">
      <el-table-column
          type="selection"
          align="center"
          width="55">
      </el-table-column>
      <el-table-column
          prop="compName"
          align="center"
          label="操作人"
          width="180">
      </el-table-column>
      <el-table-column
          prop="compCode"
          align="center"
          label="接收人"
          width="180">
      </el-table-column>
      <el-table-column
          prop="compAddress"
          align="center"
          label="批次号">
      </el-table-column>
      <el-table-column
          prop="phone"
          align="center"
          label="手机号码">
      </el-table-column>
      <el-table-column
          prop="compAddress"
          align="center"
          label="短信状态">
      </el-table-column>
      <el-table-column
          prop="compAddress"
          align="center"
          label="发送日期">
      </el-table-column>
      <el-table-column
          prop="compAddress"
          align="center"
          label="发送内容">
      </el-table-column>
      <el-table-column
          prop="compAddress"
          align="center"
          label="错误信息">
      </el-table-column>
    </custom-tables>
  </div>
</template>

<script>
import CustomTables from "../../../components/common/CustomTables";
import SearchHead from "../../../components/common/SearchHead";

export default {
  name: "smsLogs",
  components: { SearchHead, CustomTables},
  data(){
    return {
      total:0,
      tableData:[],
      searchForm:{},
    }
  },
  methods:{
    handleCurrentChange(pageIndex){
      console.log('pageIndex',pageIndex)
    },
    handleSizeChange(pageSize){
      console.log('pageSize', pageSize)
    },
    headReset(){

    },
    headSearch(){

    },
    selectionChange(val){
      console.log(val)
    },
  }
}
</script>

<style scoped>

</style>